// ****** To be deprecated, use Context instead *******

import axios from './customAxios';
import storageSvc from './storage';
import { setIsAuth } from '../reducer';
import { Config } from 'src/config';

const API_URL = Config.apiURL;

const signin = async ({ email, password }) => {
  const { data } = await axios.get(
    `${API_URL}/utils/loginbyemail/${email}/${password}`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  let user = data.user;

  try {
    const userRs = await axios.get(
      `${API_URL}/usercontact/users/${data.user.user_id}`,
    );

    if (userRs && userRs?.data?.is_success) user = userRs?.data?.users;
  } catch (error) {}

  const res = { ...data, user };
  setIsAuth({ isAuthenticated: true, res });

  storageSvc.setItem('@auth', res);

  return res;
};

const signup = async (user) => {
  const { data } = await axios.post(
    `${API_URL}/usercontact/userspassword/`,
    user,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data;
};

const changePassword = async (password) => {
  const user = getLocalUser();
  const { data } = await axios.put(
    `${API_URL}/usercontact/userpassword/${user.user_id}`,
    password,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data;
};

const isAuthenticated = () => {
  const dt = storageSvc.getItem('@auth');

  return dt && dt.user !== null;
};

const getAuthData = () => {
  return storageSvc.getItem('@auth');
};

const getLocalUser = () => {
  return storageSvc.getItem('@auth')?.user;
};

const signOut = () => {
  storageSvc.deleteItem('@auth');
  storageSvc.deleteItem('@selected_company');
};

const signin2 = async (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/login`, body)
      .then(async (result) => {
        if (result && result?.data?.is_success) {
          let user = result?.data?.users;
          let authRes = {
            ...result?.data,
            user: user,
          };

          if (user && user.user_id) {
            await axios
              .get(
                `${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/${user.user_id}`,
              )
              .then((result2) => {
                console.log('result2', result2);
                if (result2?.data?.is_success && result2.data.users) {
                  let defaultUserData = result2?.data?.users;
                  let userData = {
                    ...defaultUserData,
                    sysInfo_user_id: defaultUserData.user_id,
                  };
                  if (defaultUserData.company_user_id) {
                    userData = {
                      ...userData,
                      user_id: defaultUserData.company_user_id,
                    };
                  }
                  authRes = {
                    ...authRes,
                    user: userData,
                  };
                }
              })
              .catch((error2) => {})
              .finally(() => {
                resolve(authRes);
              });
          } else {
            resolve(authRes);
          }

          setIsAuth({ isAuthenticated: true, res: authRes });
          storageSvc.setItem('@auth', authRes);
        } else {
          reject(result);
        }
      })
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

const signup2 = async (user) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/register`,
        user,
      )
      .then((result) => {
        if (result && result?.data?.is_success) {
          resolve(result.data);
        } else {
          reject(result);
        }
      })
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

const acceptInvitation = async (query = {}, body = {}) => {
  const { username, company_id, role_id } = query;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${process.env.REACT_APP_USERCONTACT_URL}/sysinfouserroles/accept/username/${username}/company/${company_id}/role/${role_id}`,
        body,
      )
      .then((result) => {
        if (result && result?.data?.is_success) {
          resolve(result.data);
        } else {
          reject(result);
        }
      })
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

const getSysinfoUserCompanies = async (userId) => {
  let result = [];
  if (userId) {
    try {
      let res = await axios.get(
        `${API_URL}/usercontact/sysinfouserrolescompanies/user/${userId}`,
      );

      if (res?.data?.is_success) {
        let companyList = res.data.users.filter(
          (com) =>
            com.publish_status_name !== null &&
            com.publish_status_name !== 'Draft',
        );
        result = companyList || [];
      }
    } catch (error) {}
  }
  return result;
};

const authSvc = {
  signin,
  signOut,
  signup,
  changePassword,
  getLocalUser,
  isAuthenticated,
  getAuthData,
  signin2,
  signup2,
  acceptInvitation,
  getSysinfoUserCompanies,
};

export default authSvc;
