import SvgIcon from '@material-ui/core/SvgIcon';
import PersonIcon from '@material-ui/icons/Person';

// nav list
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

// top nav
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

// dashboard cards
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

// content
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import FolderSpecial from '@material-ui/icons/FolderSpecial';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FindInPage from '@material-ui/icons/FindInPage';
import Attachment from '@material-ui/icons/Attachment';
import Web from '@material-ui/icons/Web';
import { ReactComponent as teachingIcon } from '../../assets/nav/teaching.svg';
import { ReactComponent as bookkeepingIcon } from '../../assets/nav/bookkeeping.svg';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { ReactComponent as kycIcon } from '../../assets/nav/kyc.svg';
import { ReactComponent as digitalmarketingIcon } from '../../assets/nav/digitalmarketing.svg';
import { ReactComponent as payrollIcon } from '../../assets/nav/payroll.svg';
import { ReactComponent as taxIcon } from '../../assets/nav/tax.svg';
import VpnKey from '@material-ui/icons/VpnKey';

const PersonSettingIcon = (props) => {
  return (
    <SvgIcon>
      <PersonIcon />
    </SvgIcon>
  );
};
export const navGroups = [
  {
    title: 'Main',
    children: [
      {
        title: 'Overview',
        icon: DashboardOutlinedIcon,
        path: '/overview',
      },
    ],
  },
  {
    title: 'Complicane & Corporate',
    children: [
      {
        title: 'Secretarial',
        icon: FolderSpecial,
        children: [
          {
            title: 'Dashboard',
            path: '/',
          },
          {
            title: 'Request',
            path: '/request',
          },
          {
            title: 'Document',
            path: '/document',
          },
          {
            title: 'Product',
            path: '/product',
          },
          {
            title: 'Tasklist',
            path: '/tasklist',
          },
          {
            title: 'Boardroom',
            path: '/boardroom',
          },
          {
            title: 'Cap Table',
            path: '/cap-table',
          },
          {
            title: 'Manage User',
            path: '/manage_user',
          },
          {
            title: 'Company Account Settings',
            path: '/company-account-settings',
          },
        ],
      },
    ],
  },
  {
    title: 'Data Protection',
    children: [
      {
        title: 'Data Protection',
        icon: VerifiedUser,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'KYC / CDD',
    children: [
      {
        title: 'KYC / CDD',
        icon: kycIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Business Banking',
    children: [
      {
        title: 'Bank Account',
        icon: LocalAtmIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Marketing',
    children: [
      {
        title: 'Website Development',
        icon: Web,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Digital Marketing',
    children: [
      {
        title: 'Digital Marketing',
        icon: digitalmarketingIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Human Capital',
    children: [
      {
        title: 'Human Resource Management',
        icon: teachingIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Payroll',
    children: [
      {
        title: 'Payroll',
        icon: payrollIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Accounts',
    children: [
      {
        title: 'Book Keeping / Accounting',
        icon: bookkeepingIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Tax Filing',
    children: [
      {
        title: 'Tax Filing',
        icon: taxIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Audits',
    children: [
      {
        title: 'Engage Auditor',
        icon: FindInPage,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Procurement',
    children: [
      {
        title: 'Office Supplies',
        icon: Attachment,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Software Licenses',
    children: [
      {
        title: 'Software Licenses',
        icon: VpnKey,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Virtual C-Suite',
    children: [
      {
        title: 'Virtual Chief Financial Officer',
        icon: PermIdentityIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
];
