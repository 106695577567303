import {
  Paper,
  Box,
  Divider,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  LinearProgress,
  Badge,
  ButtonBase,
  Modal,
  Fade,
  Backdrop,
  Button,
  Avatar,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from 'react';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ConfigContext } from 'src/contexts';
import CompanyDropdown from 'src/components/CompanyDropdown';
import AuthLayout from 'src/components/AuthLayout';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useParams, useHistory } from 'react-router-dom';
import { useProvideRequest } from 'src/hooks/useRequests';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import ClearAllOutlinedIcon from '@material-ui/icons/ClearAllOutlined';
import AddIcon from '@material-ui/icons/Add';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useProvideCompany } from 'src/hooks/useCompany';
import DetailList from 'src/components/DetailList';
import {
  OpenBankAccountApplicationForm,
  CloseBankAccountApplicationForm,
  CessationApplicationForm,
  OfficerApplicationForm,
  CompanyAddressApplicationForm,
  CompanyNameApplicationForm,
  DeclareInterimDividendApplicationForm,
  FinancialYearApplication,
  BusinessActivityApplicationForm,
  OtherRequestApplicationForm,
  ShareAllotmentApplicationForm,
  ShareApplicationForm,
  ShareholderApplicationForm,
  ShareTransferApplicationForm,
  IndividualParticularApplicationForm,
  CompanyAuditorApplicationForm,
  AuditorCessationApplicationForm,
} from 'src/components/Corpsec/Requests/Details/applications';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  dashboardBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '10px',
    padding: 15,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: '#FFFFFF',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    marginBottom: '15px',
  },
  modalPaper: {
    boxShadow: '#191919',
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    borderRadius: 20,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 10,
    backgroundColor: '#3DD598',
    color: 'white',
  },
}))(Badge);

const Details = () => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const { smBelow, mdBelow } = useBreakpoints();
  const { company } = useContext(ConfigContext);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { requestDetails, ListRequestDetails } = useProvideRequest();

  useEffect(() => {
    if (params.id) {
      ListRequestDetails(params.id);
    }
  }, [params.id]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // const DetailsSelection = ['Overview', 'Files', 'Invoice', 'Activity log'];
  const DetailsSelection = ['Overview'];
  const RequestOverview = ({ label, description, bold }) => {
    return (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            style: {
              whiteSpace: 'normal',
              fontWeight: bold ? 'bold' : null,
            },
            variant: 'body1',
          }}
          primary={label}
        />

        <ListItemSecondaryAction>
          <Typography
            style={{ fontWeight: bold ? 'bold' : null }}
            variant="body1"
          >
            {!!description && description !== 'Invalid date'
              ? description
              : '-'}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const RenderApplication = () => {
    switch (requestDetails.company_application_type_id) {
      case 1:
        return <ShareApplicationForm request={requestDetails} />;
      case 2:
        return <ShareTransferApplicationForm request={requestDetails} />;
      case 3:
        return <CompanyAddressApplicationForm request={requestDetails} />;
      case 4:
        // Change Company Name
        return <CompanyNameApplicationForm request={requestDetails} />;
      case 5:
        // Appointment of Director
        // Appointment of Secretary
        return <OfficerApplicationForm request={requestDetails} />;
      case 6:
        return <ShareholderApplicationForm request={requestDetails} />;
      case 7:
        // Resignation of Administrator
        // Resignation of Secretary
        return <CessationApplicationForm request={requestDetails} />;
      case 8:
        // Change Director Particulars
        return <IndividualParticularApplicationForm request={requestDetails} />;
      case 9:
        return <FinancialYearApplication request={requestDetails} />;
      case 10:
        return <OpenBankAccountApplicationForm request={requestDetails} />;
      case 11:
        return <CloseBankAccountApplicationForm request={requestDetails} />;
      case 12:
        return <BusinessActivityApplicationForm request={requestDetails} />;
      case 13:
        return (
          <DeclareInterimDividendApplicationForm request={requestDetails} />
        );
      case 14:
        return <OtherRequestApplicationForm request={requestDetails} />;
      case 15:
        return <ShareAllotmentApplicationForm request={requestDetails} />;
      case 16:
        return <CompanyAuditorApplicationForm request={requestDetails} />;
      case 18:
        return <AuditorCessationApplicationForm request={requestDetails} />;
      default:
        return <></>;
    }
  };

  const BackButton = () => {
    return (
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          history.push(history.goBack());
        }}
      >
        Back
      </Button>
    );
  };

  return (
    <LoadingOverlay
      active={Object.keys(requestDetails).length === 0}
      spinner={<ClipLoader color="white" />}
      styles={{
        wrapper: {
          overflow:
            Object.keys(requestDetails).length === 0 ? 'hidden' : 'initial',
        },
      }}
    >
      {/* <AuthLayout> */}
      <Container
        maxWidth="xl"
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Paper>
          {smBelow && <BackButton />}
          <Box
            className={classes.dashboardBox}
            flexDirection={smBelow ? 'column' : 'row'}
          >
            <Box>
              {!smBelow && <BackButton />}
              <Typography variant="h1">Request Details</Typography>
              <BreadcrumbsNav
                pathList={[
                  { path: 'Home', route: ROUTES.DASHBOARD },
                  {
                    path: 'Corporate Secretary',
                    route: ROUTES.CORPSEC,
                  },
                  {
                    path: 'Requests',
                    route: ROUTES.CORPSEC_DASHBOARD,
                  },
                  {
                    path: 'Request Details',
                    color: 'primary',
                  },
                ]}
              />
            </Box>
            <div style={{ flexGrow: 1 }} />
            <CompanyDropdown />
          </Box>

          <Divider flexItem style={{ height: '1px' }} />

          <Box display="flex">
            {!smBelow && (
              <>
                <List style={{ width: '20%' }}>
                  {DetailsSelection.map((item, index) => {
                    return (
                      <ListItem
                        key={item}
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                      >
                        <ListItemText primary={item} />
                        <ListItemIcon>
                          <ChevronRightIcon />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </List>
                <Box p={5} />
              </>
            )}

            <Box p={2} style={{ width: smBelow ? '100%' : '70%' }}>
              <Typography variant="h1">Overview</Typography>
              <Box p={1} />

              <Paper
                elevation={0}
                variant={smBelow ? 'elevation' : 'outlined'}
                style={{
                  borderRadius: smBelow ? '0px' : '4px',
                  flex: '1 1 0%',
                }}
              >
                <List subheader={<li />} style={{ paddingBottom: 0 }}>
                  <ListItem
                    style={{
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          fontWeight: 'bold',
                        },
                        variant: 'body2',
                      }}
                      primary={'Request ID: #' + params.id}
                    />
                  </ListItem>
                  <Divider />
                  <DetailList
                    label="Request"
                    description={requestDetails.application_type_table_name}
                  />
                  <Divider />
                  <DetailList
                    label="Company Name"
                    description={requestDetails.company_name}
                  />
                  <Divider />
                  <DetailList
                    label="Company Registration No"
                    description={requestDetails.company_registration_no}
                  />
                  <Divider />
                  <DetailList
                    label="Date"
                    description={moment(requestDetails.created_date)
                      .utc('+0800')
                      .format('YYYY-MM-DD HH:mm')}
                  />
                  <Divider />
                  {/* <RequestOverview
                    label="Total Amount"
                    description="S$100.00"
                    bold
                  />
                  <Divider /> */}
                  <DetailList
                    label="Status"
                    description={requestDetails.status_name}
                  />
                </List>
              </Paper>

              <RenderApplication
                typeId={requestDetails.company_application_type_id}
              />
            </Box>
          </Box>
        </Paper>
      </Container>
      {/* </AuthLayout> */}
    </LoadingOverlay>
  );
};

export default Details;
