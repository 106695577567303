import React from 'react';
import useStyles from './DescriptionBox.styles';
import { Box, Container, Typography, Paper, Button } from '@material-ui/core';

const ContentBox = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.contentPaper}>
      <Typography style={{ color: '#213053', fontWeight: 900, fontSize: 48 }}>
        Web Builder
      </Typography>
      <Box p={1} />
      <Typography variant="body1" style={{ color: '#213053' }}>
        Build your website in minutes using our unique web blocks. Zero coding
        required.
      </Typography>
      {/* <Box p={1} />
      <Button variant="contained" color="primary">
        Get Started
      </Button> */}
    </Paper>
  );
};

const DescriptionBox = ({ img }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container>
        <div
          className={classes.background}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
        {ContentBox()}
      </Container>
    </Box>
  );
};

export default DescriptionBox;
