import {
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  Divider,
  ButtonBase,
  Paper,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import AuthLayout from 'src/components/AuthLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ConfigContext } from 'src/contexts';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import useStyles from './CompanyDropdown.styles';
import { useHistory } from 'react-router-dom';

const CompanyDropdown = ({ children, RoleDescAlign }) => {
  const current = new Date();
  const { smBelow } = useBreakpoints();
  const history = useHistory();
  const classes = useStyles();
  const {
    cartList,
    confirmationDialog,
    company,
    companyList,
    setCompany,
    companyLoading,
  } = useContext(ConfigContext);
  const [openCompany, setOpenCompany] = useState(false);
  const [othersCompanyList, setOthersCompanyList] = useState([]);
  const [collaborationCompanyList, setCollaborationCompanyList] = useState([]);

  useEffect(() => {
    const othersList = companyList.filter(
      (c) => c.company_id !== company.company_id,
    );
    setOthersCompanyList(othersList);
  }, [company]);

  function truncate(str, num) {
    if (str) return str.length > num ? str.substring(0, num) + '...' : str;
  }

  const handleCloseCompany = () => {
    setOpenCompany(false);
  };

  let RoleDesc = '';
  switch (company.role_id) {
    case 1:
      RoleDesc = 'You are superadmin';
      break;
    case 2:
      RoleDesc = 'You can view & make request';
      break;
    case 3:
      RoleDesc = 'You can only make request';
      break;
    case 4:
      RoleDesc = 'You can only view';
      break;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" className={classes.companyBox}>
        <BusinessIcon />
        <Box pr={1} />
        <Select
          value={company.company_id}
          open={openCompany}
          onClose={handleCloseCompany}
          onOpen={() => setOpenCompany(true)}
          IconComponent={ExpandMore}
          disableUnderline
          className={classes.companyselect}
        >
          <Typography variant="caption" className={classes.companyTitle}>
            Current Company
          </Typography>
          <MenuItem className={classes.companyItem} value={company.company_id}>
            <Typography variant="h5" noWrap>
              {company.company_name}
            </Typography>
          </MenuItem>

          {othersCompanyList.length > 0 && [
            <Divider variant="middle" key="key1" />,
            <Typography
              variant="caption"
              className={classes.companyTitle}
              key="key2"
            >
              Others
            </Typography>,
            [
              ...othersCompanyList.map((item, index) => (
                <MenuItem
                  key={item.company_name}
                  value={item.company_id}
                  className={classes.companyItem}
                  onClick={() => {
                    if (item.publish_status_name !== 'Terminated') {
                      setCompany(item.company_id);
                      handleCloseCompany();
                    }
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    style={{
                      color:
                        item.publish_status_name === 'Terminated'
                          ? 'grey'
                          : 'black',
                    }}
                  >
                    {truncate(item.company_name, 25)}
                  </Typography>
                </MenuItem>
              )),
            ],
          ]}

          {collaborationCompanyList.length > 0 && [
            <Divider variant="middle" key="key3" />,
            <Typography
              variant="caption"
              className={classes.companyTitle}
              key="key4"
            >
              Collaboration
            </Typography>,
            [
              ...collaborationCompanyList.map((item, index) => (
                <MenuItem
                  key={item.company_name}
                  value={item.company_id}
                  className={classes.companyItem}
                  onClick={() => {
                    setCompany(item.company_id);
                    handleCloseCompany();
                  }}
                >
                  <Typography variant="h5" noWrap>
                    {truncate(item.company_name, 25)}
                  </Typography>
                </MenuItem>
              )),
            ],
          ]}
          {/* {othersCompanyList.length > 0 && (
            <>
              <Divider variant="middle" />
              <Typography variant="caption" className={classes.companyTitle}>
                Others
              </Typography>
              {othersCompanyList.map((item, index) => (
                <MenuItem
                  key={item.company_name}
                  value={item.company_id}
                  className={classes.companyItem}
                  onClick={() => {
                    if (item.publish_status_name !== 'Terminated') {
                      setCompany(item.company_id);
                      handleCloseCompany();
                    }
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    style={{
                      color:
                        item.publish_status_name === 'Terminated'
                          ? 'grey'
                          : 'black',
                    }}
                  >
                    {truncate(item.company_name, 25)}
                  </Typography>
                </MenuItem>
              ))}
            </>
          )}

          {collaborationCompanyList.length > 0 && (
            <>
              <Divider variant="middle" />
              <Typography variant="caption" className={classes.companyTitle}>
                Collaboration
              </Typography>
              {collaborationCompanyList.map((item, index) => (
                <MenuItem
                  key={item.company_name}
                  value={item.company_id}
                  className={classes.companyItem}
                  onClick={() => {
                    setCompany(item.company_id);
                    handleCloseCompany();
                  }}
                >
                  <Typography variant="h5" noWrap>
                    {truncate(item.company_name, 25)}
                  </Typography>
                </MenuItem>
              ))}
            </>
          )} */}

          <Divider variant="middle" />
          <ButtonBase onClick={() => history.push(ROUTES.ONBOARDING_COMPANY)}>
            <Typography variant="h5" className={classes.addNewCompany}>
              + Add New Company Account
            </Typography>
          </ButtonBase>
        </Select>
      </Box>
      <Typography
        variant="h6"
        align={RoleDescAlign ? RoleDescAlign : smBelow ? 'center' : 'right'}
        style={{ marginTop: smBelow ? '25px' : 0 }}
      >
        {RoleDesc}
      </Typography>
    </Box>
  );
};

export default CompanyDropdown;
