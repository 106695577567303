import React, { useEffect, useContext, useRef } from 'react';
import {
  Typography,
  Box,
  Paper,
  Button,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import useStyles from './ManageUser.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MaterialTable from 'material-table';
import TitleLayout from 'src/components/TitleLayout';
import ROUTES from 'src/constants/routes';
import { ConfigContext } from 'src/contexts';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import ButtonModal from 'src/components/ButtonModal';
import { useProvideSysInfoUserRolesTable } from 'src/hooks/useSysInfoUserRoles';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import { FormRadioGroupDetails } from 'src/components/Form';
import useSnackbar from 'src/hooks/useSnackbar';

const ManageUser = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { company, user } = useContext(ConfigContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inviteNewUserModal, setInviteNewUserModal] = React.useState(false);
  const [changeRoleModal, setChangeRoleModal] = React.useState(false);
  const [userRole, setUserRole] = React.useState('2');
  const [selectedUser, setSelectedUser] = React.useState();
  const [currentRole, setCurrentRole] = React.useState();

  const submitRef = useRef(null);

  const {
    sysInfoUserRoles,
    ListSysInfoUserRoles,
    AddSysInfoUserRoles,
    UpdateSysInfoUserRoles,
    DeleteSysInfoUserRoles,
  } = useProvideSysInfoUserRolesTable();

  useEffect(() => {
    ListSysInfoUserRoles(company.company_id);
  }, [company.company_id]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const newRole = sysInfoUserRoles.filter(
      (u) => u.sysinfo_role_username == user.email,
    );
    setCurrentRole(newRole[0]?.role_id);
  }, [sysInfoUserRoles]);

  const mapUserTable = () => {
    return sysInfoUserRoles.map((user, index) => ({
      sysinfo_role_username: user.sysinfo_role_username,
      name: user.first_name
        ? user.first_name + ' ' + user.last_name
        : user.sysinfo_role_username,
      role: user.role_name,
      last_login: user.last_login,
      invitation_accepted: user.invitation_accepted,
      action: user.action,
    }));
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const formikOnSubmit = async (values, actions) => {
    const res = await AddSysInfoUserRoles({
      username: values.email,
      role_id: values.role,
      company_id: company.company_id,
      user_id: user.user_id,
      created_by: user.user_id,
    });

    if (res.status === 'success') {
      setInviteNewUserModal(false);
    } else {
      showErrorSnackbar(`Email exists, please use another email address`);
    }
  };

  const submitChangeRole = async (values, actions) => {
    const res = await UpdateSysInfoUserRoles(selectedUser, company.company_id, {
      role_id: userRole,
    });
    if (res.status === 'success') {
      showSuccessSnackbar(`Change role successfully`);
      setChangeRoleModal(false);
    }
  };

  const UserModalForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          role: '2',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
        innerRef={submitRef}
      >
        {(props) => (
          <Form>
            <Paper
              elevation={0}
              style={{
                borderRadius: 0,
              }}
            >
              <Divider />
              <Box p={4} pb={0}>
                <FormTextField
                  name="email"
                  fullWidth
                  placeholder="test@email.com, test2@email.com"
                  shrink
                  variant="outlined"
                />
              </Box>
              <Divider />

              <FormRadioGroupDetails
                name="role"
                defaultValue="2"
                onChange={(event) => {
                  props.setFieldValue('role', event.target.value);
                }}
                options={[
                  {
                    value: '1',
                    label: 'Superadmin',
                    desc:
                      "The role is for people who need to manage corporate secretary's request.",
                  },
                  {
                    value: '2',
                    label: 'View & make request',
                    desc:
                      'This role is for people who need to view share information, and connected accounts, but can’t edit any of them.',
                  },
                  {
                    value: '3',
                    label: 'View only',
                    desc:
                      "The role is for people who need to manage corporate secretary's request.",
                  },
                  {
                    value: '4',
                    label: 'Request only',
                    desc:
                      'This role is for people who need to view share information, and connected accounts, but can’t edit any of them.',
                  },
                ]}
              />
            </Paper>
          </Form>
        )}
      </Formik>
    );
  };

  const UserModal = () => {
    return (
      <Paper
        elevation={0}
        style={{
          borderRadius: 0,
        }}
      >
        <Divider />
        <RadioGroup
          value={userRole}
          onChange={(event) => {
            setUserRole(event.target.value);
          }}
        >
          <Box p={2} pl={5} display="flex" alignItems="center">
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Superadmin"
              style={{ width: smBelow ? '30%' : '20%' }}
            />
            <Typography
              variant="body1"
              style={{ width: smBelow ? '70%' : '80%' }}
            >
              The role is for people who need to manage corporate secretary’s
              request.
            </Typography>
          </Box>
          <Divider />
          <Box p={2} pl={5} display="flex" alignItems="center" width="100%">
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="View & make request"
              style={{ width: smBelow ? '30%' : '20%' }}
            />
            <Typography
              variant="body1"
              style={{ width: smBelow ? '70%' : '80%' }}
            >
              The role is for people who need to manage corporate secretary’s
              request.
            </Typography>
          </Box>
          <Divider />
          <Box p={2} pl={5} display="flex" alignItems="center">
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="View only"
              style={{ width: smBelow ? '30%' : '20%' }}
            />
            <Typography
              variant="body1"
              style={{ width: smBelow ? '70%' : '80%' }}
            >
              The role is for people who need to manage corporate secretary’s
              request.
            </Typography>
          </Box>
          <Divider />

          <Box p={2} pl={5} display="flex" alignItems="center">
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="Request only"
              style={{ width: smBelow ? '30%' : '20%' }}
            />
            <Typography
              variant="body1"
              style={{ width: smBelow ? '70%' : '80%' }}
            >
              This role is for people who need to view share information, and
              connected accounts, but can’t edit any of them.
            </Typography>
          </Box>
        </RadioGroup>
      </Paper>
    );
  };

  return (
    <>
      <ButtonModal
        isModalOpen={inviteNewUserModal}
        onCloseModal={() => setInviteNewUserModal(false)}
        label="Invite new user"
        subLabel="Enter the email addresses of the users you'd like to invite, and choose the role they should have."
        onPress1={() => setInviteNewUserModal(false)}
        onPress2={() => submitRef.current.handleSubmit()}
        modalHeight="70%"
      >
        <UserModalForm onCloseModal={() => setInviteNewUserModal(false)} />
      </ButtonModal>
      <ButtonModal
        isModalOpen={changeRoleModal}
        onCloseModal={() => setChangeRoleModal(false)}
        label="Change Role"
        subLabel="Choose the role for the user."
        onPress1={() => setChangeRoleModal(false)}
        onPress2={() => submitChangeRole()}
      >
        <UserModal onCloseModal={() => setChangeRoleModal(false)} />
      </ButtonModal>

      <TitleLayout
        title="Manage Users"
        pathList={[
          { path: 'Home', route: ROUTES.DASHBOARD },
          {
            path: 'Manage User',
            color: 'primary',
          },
        ]}
      >
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
            padding: smBelow ? 20 : 40,
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h4">{company.company_name}</Typography>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              style={{
                textTransform: 'initial',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setInviteNewUserModal(true)}
            >
              <AddIcon fontSize="small" /> New User
            </Button>
          </Box>
          <Box p={1} />
          <MaterialTable
            options={{
              filtering: false,
              paging: false,
              toolbar: false,
              draggable: false,
              sorting: false,
              headerStyle: {
                backgroundColor: '#FAFAFB',
                paddingRight: 100,
              },
              rowStyle: { backgroundColor: '#FEFEFE' },
            }}
            style={{ width: '100%', zIndex: 0 }}
            columns={[
              {
                title: 'NAME',
                field: 'name',
                render: (rowData) => (
                  <Box display="flex" alignItems="center">
                    {rowData.name}
                    {rowData.name === user.email && (
                      <>
                        <Box p={1} />
                        <Box
                          style={{
                            backgroundColor: '#BCE2FF',
                            borderRadius: 8,
                            padding: 5,
                            fontSize: 12,
                          }}
                        >
                          You {rowData.email}
                        </Box>
                      </>
                    )}
                  </Box>
                ),
              },
              { title: 'ROLE / AUTHORIZATION', field: 'role' },
              {
                title: 'LAST LOGIN',
                field: 'last_login',
                render: (rowData) => (
                  <Box display="flex" alignItems="center">
                    {rowData.last_login ? (
                      rowData.last_login.substring(0, 10)
                    ) : (
                      <>
                        {rowData.invitation_accepted == 0 && (
                          <Box
                            style={{
                              backgroundColor: '#92E6AF',
                              borderRadius: 8,
                              padding: 8,
                              fontSize: 12,
                            }}
                          >
                            Invitation sent
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                ),
              },
              {
                title: 'ACTION',
                field: 'action',
                hidden: currentRole === 3,
                render: (rowData) => (
                  <Box
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: 'initial',
                        backgroundColor: '#F0F3F6',
                      }}
                      onClick={() => {
                        setSelectedUser(rowData.sysinfo_role_username);
                        setChangeRoleModal(true);
                      }}
                    >
                      Change Role
                    </Button>
                    <Box p={1} />
                    {rowData.name !== user.email && (
                      <Button
                        variant="contained"
                        style={{
                          textTransform: 'initial',
                          backgroundColor: '#FFFFFF',
                        }}
                        onClick={() =>
                          DeleteSysInfoUserRoles(
                            rowData.sysinfo_role_username,
                            company.company_id,
                            {
                              deleted_by: user.user_id,
                            },
                          )
                        }
                      >
                        Remove User
                      </Button>
                    )}
                  </Box>
                ),
              },
            ]}
            data={mapUserTable()}
          />
          <Box p={1} />
          <Typography variant="h6" className={classes.fontWeight}>
            {sysInfoUserRoles.length} Users
          </Typography>
          <Box p={smBelow ? 1 : 5} />
        </Paper>
      </TitleLayout>
    </>
  );
};

export default ManageUser;
