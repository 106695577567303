import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Avatar,
  Link,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { ConfigContext } from 'src/contexts';
import CheckIcon from '@material-ui/icons/Check';

import { Formik, Form, Field } from 'formik';
import yellow from '@material-ui/core/colors/yellow';
import cyan from '@material-ui/core/colors/cyan';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import crypto from 'crypto';

import useBreakpoints from 'src/hooks/useBreakpoints';
import commonSvc from '../../services/common';
import companySvc from '../../services/company';
import documentSvc from '../../services/document';
import { S3UploadAndInsertDocuments } from '../../utils/aws';
import ROUTES from '../../constants/routes';
import useSnackbar from '../../hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import { useProvideOrder } from 'src/hooks/useOrder';
import { useProvideCheckout } from 'src/hooks/useCheckoutIntegration';

import ProgressLoader from '../Progress';
import StepsDisplay from './StepsDisplay';
import Layout from './Layout';
import {
  numberOfEmployees,
  annualRevenues,
  totalMonthlyCardSpends,
  entityCategories,
  industries,
} from '../../constants/onboarding';

import { sgdFormat } from '../../utils/currency';
import useQueryParams from '../../hooks/useQueryParams';

import StepFormContentWrapper from './StepForms/StepFormContentWrapper';
import {
  fieldNames,
  address_fields,
  order_fields,
  staticProductOptions,
  Form0,
  Form1,
  Form13,
  Form5,
  Form7,
  Form8,
  FormCart,
  FormCheckout,
  fieldNames_0,
  fieldNames_1,
  fieldNames_2,
  fieldNames_3,
  fieldNames_4,
} from './StepForms';

const {
  fieldName_productId,
  fieldName_checkbox0,
  fieldName_position,
  fieldName_checkbox1,
  fieldName_registrationCountry,
  fieldName_businessLegalName,
  fieldName_entityCategory,
  fieldName_entityType,
  fieldName_businessNumber,
  fieldName_natureOfIndustry,
  fieldName_natureOfSubIndustry,
  fieldName_productDescription,
  fieldName_companyWebsite,
  fieldName_numberOfEmployee,
  fieldName_annualTurnover,
  fieldName_addressUnit,
  fieldName_addressLine1,
  fieldName_addressLine2,
  fieldName_addressLine3,
  fieldName_postcode,
  fieldName_state_id,
  fieldName_addressCountry,
  fieldName_businessFiles,
  fieldName_checkbox2,
  fieldName_created_by,
  fieldName_onboard_status_id,

  fieldName_name,
  fieldName_email,
  fieldName_contactNumber,

  fieldName_directorName,
  fieldName_directorPassportIC,
  fieldName_directorEmail,
  fieldName_directorContactNumber,
} = fieldNames;

const useCustomCommonAPI = (api, props) => {
  const { respondKey, labelKey, valueKey } = props;

  const [value, setValue] = useState({
    options: [],
    rawData: [],
  });

  useEffect(() => {
    (async () => {
      const apiRes = await api();
      let options = [];
      if (apiRes && apiRes?.[respondKey]) {
        apiRes[respondKey].map((aData) => {
          options.push({
            label: aData[labelKey],
            value: aData[valueKey],
          });
        });
        setValue((prev) => {
          return {
            ...prev,
            options: options,
            rawData: apiRes[respondKey],
          };
        });
      }
    })();
  }, []);

  return {
    ...value,
  };
};

const getValidationSchemaObj = (
  fieldsData = {},
  prefix = '',
  required = true,
) => {
  let result = {};
  let fieldsDataKeys = Object.keys(fieldsData);
  fieldsDataKeys.forEach((aKey) => {
    let fieldName = prefix + fieldsData[aKey]['name'];
    result[fieldName] = Yup.string().nullable();

    if (fieldsData[aKey].required != undefined) {
      let isRequired = fieldsData[aKey].required;
      if (isRequired) {
        result[fieldName] = Yup.string()
          .required('Please fill up this field')
          .nullable();
      }
    } else {
      if (required) {
        result[fieldName] = Yup.string()
          .required('Please fill up this field')
          .nullable();
      }
    }

    if (fieldsData[aKey]?.custom) {
      result[fieldName] = fieldsData[aKey]?.custom;
    }
  });
  return result;
};

const validationSchemas = [
  Yup.object().shape({
    [fieldName_productId]: Yup.string()
      .required('Please select a type')
      .default('28'),
    // [fieldName_checkbox0]: Yup.string().required('Please check'),
  }),
  Yup.object().shape({
    [fieldName_position]: Yup.string()
      .required('Please select a type')
      .default('1'),
    [fieldName_checkbox1]: Yup.string().required('Please check'),
    [fieldName_name]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_email]: Yup.string()
      .email('Please enter a valid email')
      .required('Please fill up this field')
      .nullable(),
    [fieldName_contactNumber]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
  }),
  Yup.object().shape({
    [fieldName_productId]: Yup.string()
      .required('Please select a type')
      .default('28')
      .nullable(),
    // [fieldName_checkbox0]: Yup.string().required('Please check'),
    [fieldName_directorName]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string().required('Please fill up this field').nullable(),
      otherwise: Yup.string().default('').nullable(),
    }),
    [fieldName_directorPassportIC]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string().required('Please fill up this field').nullable(),
      otherwise: Yup.string().default('').nullable(),
    }),
    [fieldName_directorEmail]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string()
        .email('Please enter a valid email')
        .required('Please fill up this field')
        .nullable(),
      otherwise: Yup.string().default('').nullable(),
    }),
    [fieldName_directorContactNumber]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string().required('Please fill up this field').nullable(),
      otherwise: Yup.string().default('').nullable(),
    }),
    [fieldName_businessLegalName]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_registrationCountry]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_entityCategory]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_entityType]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_businessNumber]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_natureOfIndustry]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_natureOfSubIndustry]: Yup.string().nullable(),
    [fieldName_productDescription]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_companyWebsite]: Yup.string().nullable(),
    [fieldName_numberOfEmployee]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_annualTurnover]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
  }),
  Yup.object().shape({
    [fieldName_addressUnit]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_addressLine1]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_addressLine2]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_addressLine3]: Yup.string().default('').nullable(),
    [fieldName_postcode]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_state_id]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
    [fieldName_addressCountry]: Yup.string()
      .required('Please fill up this field')
      .nullable(),
  }),
  Yup.object().shape({
    [fieldName_businessFiles]: Yup.array().test({
      message: 'Please choose the required files',
      test: (arr) => arr.length > 0,
    }),
    [fieldName_checkbox2]: Yup.string().required('Please check'),
  }),
];

let validationSchemaObj_billing = {
  billing: Yup.object().shape(getValidationSchemaObj(address_fields)),
  [order_fields.remark.name]: Yup.string(),
  [order_fields.payment_method.name]: Yup.string().required(
    'Please select a payment method',
  ),
  // [fields.differentShipping.name]: Yup.boolean(),
  [order_fields.tnc.name]: Yup.boolean()
    .required()
    .oneOf([true], 'Please tick here to proceed'),
};

// let validationSchemaObj_shipping = {
//   ...validationSchemaObj_billing,
//   shipping: Yup.object().shape(getValidationSchemaObj(address_fields)),
// };

const localStorageKey = 'onboarding-company-public';
const onboardingProfileKey = 'onboarding-company-public-id';

const setFormValueLocalStorage = (values) => {
  localStorage.setItem(localStorageKey, JSON.stringify(values));
};
const getFormValueLocalStorage = () => {
  return JSON.parse(localStorage.getItem(localStorageKey)) || {};
};
const removeFormValueLocalStorage = () => {
  localStorage.removeItem(localStorageKey);
  localStorage.removeItem(onboardingProfileKey);
};

const setOnboardingProfilePublicId = (id) => {
  localStorage.setItem(onboardingProfileKey, id);
};
const getOnboardingProfilePublicId = () => {
  return localStorage.getItem(onboardingProfileKey);
};

let forms = [
  {
    id: 1,
    stepTitle: 'Business Package',
    title: 'I want to open new Meyzer360 account',
    component: Form0,
    validationSchema: validationSchemas[0],
    fieldNames: fieldNames_0,
  },
  {
    id: 2,
    stepTitle: 'Business Representative',
    title: 'I want to open new Meyzer360 account',
    component: Form1,
    validationSchema: validationSchemas[1],
    fieldNames: fieldNames_1,
  },
  {
    id: 3,
    stepTitle: 'Business Structure',
    title: 'Fill in your business information',
    component: Form5,
    validationSchema: validationSchemas[2],
    fieldNames: fieldNames_2,
  },
  {
    id: 4,
    stepTitle: 'Business Details',
    title: 'Fill in your business address',
    component: Form7,
    validationSchema: validationSchemas[3],
    fieldNames: fieldNames_3,
  },
  {
    id: 5,
    stepTitle: 'Business Documents',
    title: 'Submit your business documents',
    component: Form8,
    validationSchema: validationSchemas[4],
    fieldNames: fieldNames_4,
  },
  {
    id: 21,
    stepTitle: 'Cart',
    title: 'Selected Item(s)',
    component: FormCart,
    nextButtonLabel: 'Procceed to Checkout',
    validationSchema: Yup.object().shape({}),
  },
  {
    id: 22,
    stepTitle: 'Checkout',
    title: 'Billing Information',
    component: FormCheckout,
    nextButtonLabel: 'Place Order',
    validationSchema: Yup.object().shape(validationSchemaObj_billing),
  },
  {
    id: 6,
    stepTitle: 'Confirmation',
    title: 'Document Verification',
    component: Form13,
  },
];

const createHash = (value) => {
  const secret = value;
  const sha256 = crypto.createHash('sha256');
  let hash = sha256.update(secret).digest('hex');
  return hash;
};

const OnboardingCompanyPublic = (props) => {
  const { onSubmit } = props;

  const queryParams = useQueryParams();
  let param_isPaid = queryParams.get('paid');
  let param_selectedPackage = queryParams.get('package');
  let param_onboardProfileId = queryParams.get('onboard_profile_id');
  let param_hash = queryParams.get('hash');

  let isFromEmail =
    param_onboardProfileId != undefined && param_hash != undefined;
  let defaultValues = getFormValueLocalStorage();
  let defaultOnboadingProfileId = getOnboardingProfilePublicId();
  if (isFromEmail) {
    defaultOnboadingProfileId = param_onboardProfileId;
    defaultValues = {
      formPage: 5,
    };
  }
  const {
    formPage: defaultFormPage,
    order: orderData,
    ...restDefaultValues
  } = defaultValues;
  const { smBelow } = useBreakpoints();
  const routeHistory = useHistory();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const {
    ReadProduct,
    ListProducts,
    ListProductsPublic,
    products: defaultProducts = [],
    product,
    productLoading,
    productError,
  } = useProvideProduct();

  const {
    GetPublicOrderById,
    CreatePublicOrder,
    UpdatePublicOrder,
    GetOrderAddressPublicByOrderId,
    CreateOrderAddressPublic,
    UpdateOrderAddressPublic,
    UpdatePublicOrderByAddress,
    orderError,
    orderSubmitting,
  } = useProvideOrder();

  const {
    PaymentByLink,
    checkoutError,
    checkoutSubmitting,
  } = useProvideCheckout();

  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formPage, setFormPage] = useState(
    defaultFormPage ? defaultFormPage : 0,
  );
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [orderValues, setOrderValues] = useState({});

  // const getRemarksObj = (remarks) => {
  //   let result = {
  //     remarks: '',
  //   };

  //   if (remarks) {
  //     let matches = remarks.match(/\[(.*?)\]/);

  //     let objString = matches?.[1];
  //     if (objString) {
  //       try {
  //         let obj = JSON.parse(objString);
  //         const { name, passportIC, email, contactNumber } = obj;
  //         result[fieldName_directorName] = name || '';
  //         result[fieldName_directorPassportIC] = passportIC || '';
  //         result[fieldName_directorEmail] = email || '';
  //         result[fieldName_directorContactNumber] = contactNumber || '';
  //       } catch (error) {}
  //     }

  //     let foundIndex = remarks.indexOf(']');
  //     let actualRemarks = remarks.substring(foundIndex + 1);
  //     result['remarks'] = actualRemarks;
  //   }
  //   return result;
  // };
  const initForm = async () => {
    // fetch onboard profile public
    // fetch order
    // fetch docs
    let newDefaultValues = {};
    let defaultOrderValue = {};
    let onboardProfileRes;
    (async () => {
      if (defaultOnboadingProfileId != undefined) {
        setInitLoading(true);

        try {
          onboardProfileRes = await companySvc.getOnboardProfilePublicById(
            defaultOnboadingProfileId,
          );

          newDefaultValues = onboardProfileRes?.data?.onboard_profile || {};
          if (param_selectedPackage == 28 || param_selectedPackage == 39) {
            newDefaultValues = {
              ...newDefaultValues,
              [fieldName_productId]: param_selectedPackage,
            };
          }

          newDefaultValues = {
            ...newDefaultValues,
            // ...(getRemarksObj(newDefaultValues?.remarks) || {}),
          };
        } catch (error) {}

        let orderId = onboardProfileRes?.data?.onboard_profile?.order_id;

        if (orderId) {
          if (isFromEmail) {
            let newHash = createHash(`${param_onboardProfileId}${orderId}`);
            let isSameHash = newHash == param_hash;
            if (!isSameHash) {
              // showErrorSnackbar('Invalid Link.');
              // routeHistory.replace('/login');
              window.alert('Link Expired.');
              setInitLoading(false);
              removeFormValueLocalStorage();
              window.location.replace(window.location.origin);
              return;
            }
          }
          try {
            let orderRes = await GetPublicOrderById(orderId);
            if (orderRes?.body?.order) {
              defaultOrderValue = orderRes?.body?.order || {};

              if (defaultOrderValue?.status != 'PENDING PAYMENT') {
                // showErrorSnackbar('Invalid Link.');
                // routeHistory.replace('/login');
                window.alert('Link Expired.');
                setInitLoading(false);
                removeFormValueLocalStorage();
                window.location.replace(window.location.origin);
                return;
              }

              let billing = {
                ...defaultOrderValue,
                [address_fields.phoneNumber.name]:
                  defaultOrderValue?.[address_fields.phoneNumber.name] || '',
                [address_fields.phoneNumberCountry.name]: '',
              };
              if (defaultOrderValue?.billing_address) {
                billing = {
                  ...billing,
                  ...(defaultOrderValue?.billing_address || {}),
                };
              }
              newDefaultValues = {
                ...newDefaultValues,
                billing: billing,
              };
            }
          } catch (error) {}
        }

        try {
          let docRes = await documentSvc.getDocumentsPublic(
            167,
            29,
            defaultOnboadingProfileId,
          );
          let documents =
            docRes?.data?.document?.length > 0 ? docRes?.data?.document : [];
          let defaultDocuments = documents.map((anItem) => {
            return {
              ...anItem,
              name: anItem?.file_name,
              size: null,
              isUploaded: true,
            };
          });
          newDefaultValues = {
            ...newDefaultValues,
            [fieldName_businessFiles]: defaultDocuments,
          };
          // setOnboardProfileDocuments(documents);
        } catch (error) {}
        setOrderValues(defaultOrderValue);
        setDefaultFormValues(newDefaultValues);

        setInitLoading(false);
      } else {
        setDefaultFormValues({});
      }

      if (!newDefaultValues?.[fieldName_productId]) {
        setFormValueLocalStorage({
          formPage: 0,
        });
        setFormPage(0);
      }
      if (param_selectedPackage) {
        setFormValueLocalStorage({
          formPage: 1,
        });
        setFormPage(1);
      }
      if (param_isPaid) {
        removeFormValueLocalStorage();
        setFormPage(forms?.length - 1);
      }
      if (isFromEmail) {
        removeFormValueLocalStorage();
        setFormPage(5);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      await ListProductsPublic();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await initForm();
    })();
  }, [
    defaultOnboadingProfileId,
    param_selectedPackage,
    param_onboardProfileId,
    param_hash,
    param_isPaid,
  ]);

  let steps = forms.map((anItem) => {
    return {
      id: anItem.id,
      label: anItem.stepTitle,
    };
  });

  const { options: countryOptions = [] } = useCustomCommonAPI(
    commonSvc.countries,
    {
      labelKey: 'country_name',
      valueKey: 'country_id',
      respondKey: 'countries',
    },
  );

  const {
    options: stateOptions = [],
    rawData: statesData,
  } = useCustomCommonAPI(commonSvc.states, {
    labelKey: 'state_name',
    valueKey: 'state_id',
    respondKey: 'states',
  });

  const { options: genderOptions = [] } = useCustomCommonAPI(
    commonSvc.genders,
    {
      labelKey: 'gender_name',
      valueKey: 'gender_code',
      respondKey: 'genders',
    },
  );

  const { options: applicationTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyApplicationTypes,
    {
      labelKey: 'application_type_table_name',
      valueKey: 'company_application_type_id',
      respondKey: 'applicationtype',
    },
  );

  const { options: companyTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyTypes,
    {
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
      respondKey: 'company_types',
    },
  );

  const entityCategoriesOptions = entityCategories.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const industriesOptions = industries.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const numberOfEmployeeOptions = numberOfEmployees.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const annualRevenueOptions = annualRevenues.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  let formObj = forms?.[formPage] || {};
  const { validationSchema = {} } = formObj;
  const CurrentForm = formObj?.component;

  let yupDefaultValues = validationSchema?.cast ? validationSchema?.cast() : {};
  let mergedDefaultValues = {
    [fieldName_productId]: 28,
    [fieldName_position]: 1,
    ...yupDefaultValues,
    ...defaultFormValues,
    [order_fields.payment_method.name]: 'payment2',
  };

  const getStepFormValues = (values = {}, fields = {}) => {
    let result = {};
    let fieldKeys = Object.keys(fields);
    fieldKeys.forEach((aKey) => {
      let fieldName = fields[aKey];
      result[fieldName] = values?.[fieldName] || null;
    });

    return result;
  };

  const handleSubmitPublic = async (values) => {
    const {
      [fieldName_checkbox0]: checkValue0,
      [fieldName_checkbox1]: checkValue1,
      [fieldName_checkbox2]: checkValue2,
      [fieldName_businessFiles]: selectedFiles = [],
      [fieldName_productId]: selectedProductId,
      // [fieldName_directorName]: directorNameValue,
      // [fieldName_directorPassportIC]: directorPassportICValue,
      // [fieldName_directorEmail]: directorEmailValue,
      // [fieldName_directorContactNumber]: directorContactNumberValue,
      ...restValues
    } = values;
    let newPageIndex = formPage + 1;

    let formValues = {};
    if (formObj?.fieldNames) {
      formValues = getStepFormValues(restValues, formObj?.fieldNames);
    }
    if (newPageIndex < forms.length - 1) {
      if (formPage <= 4) {
        if (formPage == 1) {
          // create temp form value
          setLoading(true);
          try {
            if (defaultOnboadingProfileId != undefined) {
              await companySvc.updateOnboardProfilePublicById(
                defaultOnboadingProfileId,
                {
                  ...formValues,
                  [fieldName_productId]: selectedProductId,
                },
              );
            } else {
              let createOnboardProfilePublicResult = await companySvc.createOnboardProfilePublic(
                {
                  ...formValues,
                  [fieldName_productId]: selectedProductId,

                  created_by: 0,
                },
              );
              setOnboardingProfilePublicId(
                createOnboardProfilePublicResult?.data?.onboard_profile
                  ?.onboard_profile_id,
              );
              setFormValueLocalStorage({
                ...formValues,
                formPage: newPageIndex,
              });
            }
            setFormPage((prev) => newPageIndex);
          } catch (error) {
            showErrorSnackbar('Failed to submit profile.');
          }
          setLoading(false);
        } else if (formPage == 2 || formPage == 3) {
          setLoading(true);
          await (async () => {
            // submit
            let updateProfileResult;
            try {
              updateProfileResult = await companySvc.updateOnboardProfilePublicById(
                defaultOnboadingProfileId,
                {
                  ...formValues,
                  [fieldName_onboard_status_id]: 1,
                  // remarks: `[${JSON.stringify({
                  //   product:
                  //     staticProductOptions[values?.[fieldName_productId]]
                  //       ?.label,
                  //   name: directorNameValue,
                  //   passportIC: directorPassportICValue,
                  //   email: directorEmailValue,
                  //   contactNumber: directorContactNumberValue,
                  // })}]`,
                },
              );
              // removeFormValueLocalStorage();
            } catch (error) {
              showErrorSnackbar('Failed to submit profile.');
              return;
            }

            setFormValueLocalStorage({
              ...restValues,
              formPage: newPageIndex,
            });
            setFormPage((prev) => newPageIndex);
          })();
          setLoading(false);
        } else if (formPage == 4) {
          setLoading(true);
          await (async () => {
            if (selectedFiles && selectedFiles.length > 0) {
              // submit
              let orderData = {};
              let foundSelectedProduct = defaultProducts.find(
                (anItem) => anItem.id == values?.[fieldName_productId],
              );
              if (foundSelectedProduct) {
                let orderBody = {
                  // product_detail_id: 1,
                  // "last_name": "n1",

                  first_name: values?.[fieldName_name],
                  phone: values?.[fieldName_contactNumber],
                  email: values?.[fieldName_email],
                  product_id: values?.[fieldName_productId],
                  quantity: 1,
                  price: foundSelectedProduct?.price,
                  // remark: 'Test Stage Detail 1',
                  onboard_profile_id: defaultOnboadingProfileId,
                };
                if (defaultFormValues?.order_id) {
                  try {
                    let orderRes = await UpdatePublicOrder(
                      defaultFormValues?.order_id,
                      orderBody,
                    );
                    orderData = orderRes?.body?.order;
                    setOrderValues(orderData);
                  } catch (error) {}
                } else {
                  try {
                    let orderRes = await CreatePublicOrder(orderBody);
                    console.log('orderRes', orderRes);
                    orderData = orderRes?.body?.order;
                    setOrderValues(orderData);
                  } catch (error) {
                    console.log('orderRes error', error);
                  }
                }
              }

              let updateProfileResult;
              try {
                let newOnboardProfile = {
                  ...restValues,
                  [fieldName_onboard_status_id]: 1,
                  // remarks: `[${JSON.stringify({
                  //   product:
                  //     staticProductOptions[values?.[fieldName_productId]]
                  //       ?.label,
                  //   name: directorNameValue,
                  //   passportIC: directorPassportICValue,
                  //   email: directorEmailValue,
                  //   contactNumber: directorContactNumberValue,
                  // })}]`,
                  is_last_step: 1,
                  to_email: restValues?.email,
                  from_email: 'support@meyzer360.com',
                };

                if (orderData?.id != undefined) {
                  newOnboardProfile['order_id'] = orderData?.id;
                }
                updateProfileResult = await companySvc.updateOnboardProfilePublicById(
                  defaultOnboadingProfileId,
                  newOnboardProfile,
                );
              } catch (error) {
                showErrorSnackbar('Failed to submit profile.');
                return;
              }
              if (updateProfileResult?.data?.is_success) {
                try {
                  for (let i = 0; i < selectedFiles.length; i++) {
                    let aFile = selectedFiles[i];
                    if (!aFile?.isUploaded) {
                      await S3UploadAndInsertDocuments(
                        aFile,
                        167,
                        29,
                        defaultOnboadingProfileId,
                      );
                    }
                  }
                } catch (error) {
                  showErrorSnackbar('Failed to upload document(s)');
                  return;
                }
                await initForm();
              } else {
                setLoading(false);
                showErrorSnackbar('Failed to submit profile.');
              }
            } else {
              showErrorSnackbar('Please Select Document(s)');
            }

            setFormPage((prev) => newPageIndex);
            setFormValueLocalStorage({
              ...restValues,
              formPage: newPageIndex,
            });
          })();
          setLoading(false);
        } else {
          setFormValueLocalStorage({
            ...restValues,
            formPage: newPageIndex,
          });
          setFormPage((prev) => newPageIndex);
        }
      } else {
        setFormValueLocalStorage({
          ...restValues,
          formPage: newPageIndex,
        });
        setFormPage((prev) => newPageIndex);
      }
    } else {
      setLoading(true);

      await (async () => {
        let orderId = orderValues?.id;
        let orderBillingId = orderValues?.billing_address_id;

        if (orderId != undefined) {
          let billingAddressRes;
          let foundCountry = countryOptions.find((anItem) => {
            return (
              anItem.value == values?.billing?.[address_fields.country_id.name]
            );
          });
          let foundState = stateOptions.find((anItem) => {
            return (
              anItem.value == values?.shipping?.[address_fields.state_id.name]
            );
          });
          if (orderBillingId) {
            // update address
            try {
              billingAddressRes = await UpdateOrderAddressPublic(
                orderBillingId,
                {
                  ...values.billing,
                  // country: foundCountry?.label || '',
                  // state: foundState?.label || '',
                },
              );
            } catch (error) {
              console.log('UpdateOrderAddressPublic error', error);
              showErrorSnackbar(
                `Problem updating your order detail. Please refresh and try again.`,
              );
              return;
            }
          } else {
            // create address
            try {
              billingAddressRes = await CreateOrderAddressPublic({
                ...values.billing,
                created_by: 0,
                // country: foundCountry?.label || '',
              });
            } catch (error) {
              console.log('CreateOrderAddressPublic error', error);

              showErrorSnackbar(
                `Problem updating your order detail. Please refresh and try again.`,
              );
              return;
            }
          }
          console.log('billingAddressRes', billingAddressRes?.body);
          let orderBody = {
            billing_address_id: billingAddressRes?.body?.order_address?.id,
            remark: values?.[order_fields.remark.name],
          };
          let orderRes;
          try {
            orderRes = await UpdatePublicOrderByAddress(
              orderValues?.id,
              orderBody,
            );
            // orderData = orderRes?.order;
            console.log('orderResorderRes', orderRes?.body);
            // setOrderValues(orderRes?.order);
          } catch (error) {
            showErrorSnackbar(
              `Problem checking out your cart. Please refresh and try again.`,
            );
            return;
          }
          // request payment link
          if (orderRes) {
            let foundSelectedProduct = defaultProducts.find(
              (anItem) => anItem.id == values?.[fieldName_productId],
            );

            if ((foundSelectedProduct?.price || 0) == 0) {
              let redirect_url = `${window.location.origin}/onboarding-company-public?paid=true&redirect=${orderData?.id}`;
              window.location.replace(redirect_url);
            } else {
              const res = await PaymentByLink({
                billing: {
                  address: {
                    country: 'SG',
                  },
                },
                // products,
                currency: 'SGD',
                amount: (foundSelectedProduct?.price || 0) * 100,
                '3ds': {
                  enabled: false,
                  attempt_n3d: false,
                },
                // reference: String(cart.id),
                reference: `order:${orderValues?.id}`,
                return_url: `${window.location.origin}/onboarding-company-public?paid=true&redirect=${orderData?.id}`,
              });
              console.log('PaymentByLink', res);
              if (res.status === 'success') {
                removeFormValueLocalStorage();
                showSuccessSnackbar('Redirecting to payment...');
                window.location.replace(res.body._links.redirect.href);
              }
            }
          }
        } else {
          showErrorSnackbar(
            `Problem updating your order. Please refresh and try again.`,
          );
        }
      })();

      setLoading(false);
    }
  };

  if (initLoading) {
    return (
      <Backdrop open={true} style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    );
  }
  return (
    <Layout
      subHeader={
        smBelow ? (
          <Box display="flex" justifyContent="center">
            <StepsDisplay
              activeStep={formPage}
              steps={steps}
              formPage={formPage}
              horizontal={true}
            />
          </Box>
        ) : null
      }
      onBack={() => {
        window.location.replace(window.location.origin);
      }}
    >
      <Container maxWidth="lg" style={{ display: 'flex' }}>
        <Grid container spacing={2}>
          {smBelow ? null : (
            <Grid item xs={3} sm={3} md={3}>
              <Box height="100%">
                <StepsDisplay
                  activeStep={formPage}
                  steps={steps}
                  formPage={formPage}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            {productLoading ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Formik
                initialValues={mergedDefaultValues}
                validationSchema={validationSchema}
                onSubmit={() => {}}
                validateOnChange={false}
              >
                {(props) => {
                  const { values, validateForm, setTouched, touched } = props;

                  const selectedEntityCategory =
                    values[fieldName_entityCategory];
                  let foundEntityCategory = entityCategories.find(
                    (aCategory) => aCategory.label == selectedEntityCategory,
                  );
                  let entityTypeOptions = [];
                  if (
                    foundEntityCategory &&
                    foundEntityCategory.children &&
                    foundEntityCategory.children.length > 0
                  ) {
                    entityTypeOptions = foundEntityCategory.children.map(
                      (anItem) => {
                        return {
                          label: anItem.label,
                          value: anItem.label,
                        };
                      },
                    );
                  }

                  const selectedIndustry = values[fieldName_natureOfIndustry];
                  let foundIndustry = industries.find(
                    (aCategory) => aCategory.label == selectedIndustry,
                  );

                  let subIndustriesOptions = [];
                  if (
                    foundIndustry &&
                    foundIndustry.children &&
                    foundIndustry.children.length > 0
                  ) {
                    subIndustriesOptions = foundIndustry.children.map(
                      (anItem) => {
                        return {
                          label: anItem.label,
                          value: anItem.label,
                        };
                      },
                    );
                  }

                  const handlePrevStep = () => {
                    let newPageIndex = formPage - 1;
                    if (newPageIndex >= 0) {
                      setFormPage((prev) => newPageIndex);
                      setFormValueLocalStorage({
                        ...defaultValues,
                        formPage: newPageIndex,
                      });
                    }
                  };
                  const handleNextStep = () => {
                    // console.log("touched", touched);
                    // setTouched(true, true);
                    if (formPage < forms.length - 1) {
                      validateForm()
                        .then((result) => {
                          console.log('validateForm result', result);
                          setTouched({
                            ...touched,
                            ...result,
                          });
                          let canSubmit = false;
                          if (result && Object.keys(result).length == 0) {
                            canSubmit = true;
                          }
                          if (canSubmit) {
                            handleSubmitPublic(values);
                          }
                        })
                        .catch((errors) => {
                          console.log('validateForm error', errors);
                          setTouched({ ...touched, ...errors });
                        });
                    } else {
                      // redirect
                      // removeFormValueLocalStorage();
                      window.location.href = 'www.meyzer360.com';
                    }
                  };

                  return (
                    <Form style={{ height: '100%' }}>
                      <Paper display="flex" style={{ height: '100%' }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          height="100%"
                        >
                          <Box flexGrow={1}>
                            <Container maxWidth="xs" style={{ height: '100%' }}>
                              <StepFormContentWrapper
                                stepTitle={formObj?.stepTitle}
                                contentTitle={formObj?.title}
                              >
                                {CurrentForm && (
                                  <CurrentForm
                                    commonData={{
                                      countries: countryOptions,
                                      states: stateOptions,
                                      statesRawData: statesData,
                                      genders: genderOptions,
                                      applicationTypes: applicationTypeOptions,
                                      companyTypes: companyTypeOptions,
                                      entityCategories: entityCategoriesOptions,
                                      entityTypes: entityTypeOptions,
                                      industries: industriesOptions,
                                      subIndustries: subIndustriesOptions,
                                      numberOfEmployees: numberOfEmployeeOptions,
                                      annualRevenues: annualRevenueOptions,
                                      products: defaultProducts,
                                    }}
                                    showPrice={false}
                                    isPublic={true}
                                  />
                                )}
                              </StepFormContentWrapper>
                            </Container>
                          </Box>
                          <Divider />
                          {/* Footer */}
                          <Box
                            py={3}
                            px={2}
                            display="flex"
                            justifyContent="space-between"
                          >
                            {formPage > 0 && formPage < forms.length - 1 && (
                              <Button
                                variant="contained"
                                disableElevation={true}
                                style={{ width: '150px' }}
                                onClick={handlePrevStep}
                                // disabled={
                                //   param_onboardProfileId &&
                                //   param_hash &&
                                //   formPage == 5
                                // }
                              >
                                Back
                              </Button>
                            )}
                            {formPage < forms.length && (
                              <Button
                                variant="contained"
                                disableElevation={true}
                                color="primary"
                                style={{
                                  minWidth: '150px',
                                  marginLeft: 'auto',
                                }}
                                // type="submit"
                                type="button"
                                onClick={handleNextStep}
                              >
                                {formObj?.nextButtonLabel
                                  ? formObj?.nextButtonLabel
                                  : 'Next'}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Paper>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Grid>
          <Grid item sm={3} md={3} />
        </Grid>
      </Container>
      <Backdrop open={loading} style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    </Layout>
  );
};

export default OnboardingCompanyPublic;
